// extracted by mini-css-extract-plugin
export var actionButton = "welcome-module--actionButton--nWXbL";
export var actionButton2 = "welcome-module--actionButton2--WGtAJ";
export var container = "welcome-module--container--i9y0L";
export var fadeIn = "welcome-module--fadeIn--rJoPX";
export var login = "welcome-module--login--0U5-x";
export var login2 = "welcome-module--login2--gC3-M";
export var logo = "welcome-module--logo--P-qSU";
export var lowerContainer = "welcome-module--lowerContainer--Ite3-";
export var motto = "welcome-module--motto--g8-QK";
export var shadow = "welcome-module--shadow--gbna3";
export var textShadow = "welcome-module--text-shadow--iSjlP";
export var upperContainer = "welcome-module--upperContainer--e1bMe";
export var videoContainer = "welcome-module--videoContainer--P4w-v";